import { Controller } from "@hotwired/stimulus"
import { put } from "@rails/request.js"
// Connects to data-controller="bundled-proposals"
export default class extends Controller {
  static targets = ["form"]
  static values = {
    originalStandaloneProposalIds: Array,
    bundledProposalId: Number,
    companyId: Number,
    bundledProposalIndexUrl: String
  }

  connect() {
    console.log('bundled started')
  }

  toggleStandalone(event) {
    if (event?.target) {
      const form = new FormData(this.formTarget)
      if (event.target.checked) {
        form.append("standalone_to_add[]", event.target.value)
      } else {
        form.append("standalone_to_remove[]", event.target.value)
      }

      this.update(form)
    }
  }

  navigateToIndex() {
    window.location.href = this.bundledProposalIndexUrlValue
  }

  async cancel() {
    const form = new FormData()

    if (this.originalStandaloneProposalIdsValue.length > 0) {
      this.originalStandaloneProposalIdsValue?.forEach( id => {
        form.append("standalone_to_set[]", id)
      })
    } else {
      form.append("standalone_to_set[]", "")
    }

    await this.update(form)
    this.navigateToIndex()
  }

  async update(formData) {
    const url =  `/companies/${this.companyIdValue}/bundled_proposals/${this.bundledProposalIdValue}/update_standalone_proposals`

    try {
      const response = await put(url, { headers: {
        'Accept': 'application/json'
        },
        responseKind: "application/json",
        body: formData
      })

      const { json, ok } = await response

      if (ok) {

      }
    } catch(e) {
      console.error(e)
    }
  }
}
